
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { getCourierList } from '@/api/courier/courier.api'
import { ElNotification } from 'element-plus'
import xlsx from 'xlsx'
import { COURIER } from '@/constants/courier'
import dayjs from '@/utils/dayjs'

export default defineComponent({
	name: 'ReconcileInsertDialog',
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onConfirm', 'update:modelValue'],
	setup(_, { emit }) {
		const isSubmittingFile = ref(false)
		const selectedCourier = ref(null)
		const state = reactive<any>({
			datePick: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
		})
		const courierList = ref<{_id:string,name:string}[]>([])
		const courierListFilter = computed(() =>
			courierList.value.filter(
				(each) => each.name !== COURIER.FLASH_BULKY
			)
		)
		const fileList = ref<any>([])

		const confirmCancelReconcileFile = () => {
			emit('update:modelValue', false)
			emit('onConfirm', selectedCourier.value)
		}

		const onUploadFile = async ({ file }: any) => {
			try {
				isSubmittingFile.value = true
				const buffer = await file.arrayBuffer()
				const findCourierById: any = courierList.value.find(
					(each: any) => each._id === selectedCourier.value
				)

				// Flash
				if (findCourierById?.name === COURIER.FLASH) {
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[0] // Hardcode flash always position 1
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet)

					const reconciles = readExcel
						.map((each: any) => ({ trackingNumber: each['Tracking No.'] }))
						.filter((each: any) => each.trackingNumber)

					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
							startDate: state.datePick[0],
							endDate: state.datePick[1],
						},
					]
				}

				// Thailand Post
				if (findCourierById?.name === COURIER.EMS) {
				//
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[0] // Hardcode always position 1
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet, {
						header: 'A',
						range: 3,
						blankrows: false,
					})

					const reconciles = readExcel
						.map((each: any) => ({ trackingNumber: each['D'] }))
						.filter((each: any) => each.trackingNumber)

					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
							startDate: state.datePick[0],
							endDate: state.datePick[1],
						},
					]
				}

				// J&T
				if (findCourierById?.name === COURIER.JT) {
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[1] // Hardcode  position 1
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet, {
						header: 'A',
						range: 2,
					})
					const reconciles = readExcel
						.map((each: any) => ({ trackingNumber: each['A'] }))
						.filter((each: any) => each.trackingNumber)

					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
							startDate: state.datePick[0],
							endDate: state.datePick[1],
						},
					]
				}

				// Ninja Van
				if (findCourierById?.name === COURIER.NINJA_VAN) {
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[0] // Hardcode always position 0
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet)
					const reconciles = readExcel
						.map((each: any) => ({
							trackingNumber: each['Tracking ID'],
						}))
						.filter((each: any) => each.trackingNumber)

					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
							startDate: state.datePick[0],
							endDate: state.datePick[1],
						},
					]
				}

				// kerry
				if (findCourierById?.name === COURIER.KERRY) {
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[0] // Hardcode always position 0
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet, {
						header: 'A',
						range: 5,
					})
					const reconciles = readExcel
						.map((each: any) => ({ trackingNumber: each['B'] }))
						.filter((each: any) => each.trackingNumber)
					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
							startDate: state.datePick[0],
							endDate: state.datePick[1],
						},
					]
				}

				// Shopee Express
				if (findCourierById?.name === COURIER.SPX) {
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[0]
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet, {
						header: 'A',
						range: 2,
					})
					const reconciles = readExcel
						.map((each: any) => ({ trackingNumber: each['F'] }))
						.filter((each: any) => each.trackingNumber)

					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
							startDate: state.datePick[0],
							endDate: state.datePick[1],
						},
					]
				}
				//DHL
				if (findCourierById?.name === COURIER.DHL) {
					const workbook = xlsx.read(buffer)
					const sheetName = workbook.SheetNames?.[0]
					const worksheet = workbook.Sheets[sheetName]
					const readExcel = xlsx.utils.sheet_to_json(worksheet, {
						header: 'A',
						range: 6,
					})
					const reconciles = readExcel
						.map((each: any) => ({ trackingNumber: each['E'] }))
						.filter((each: any) => each.trackingNumber)
					fileList.value = [
						...fileList.value,
						{
							courierId: selectedCourier.value,
							name: `${file.name}`,
							reconciles,
						},
					]
				}
			} catch (error) {
				//
			} finally {
				isSubmittingFile.value = false
			}
		}

		const onRemove = (file: any, list: any[]) => {
			fileList.value = list
		}
		const validateFile = async (file: any) => {
			const isExistCourierInList = fileList.value.some(
				(eachFile: any) => eachFile.courierId === selectedCourier.value
			)
			if (isExistCourierInList) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'คุณมีไฟล์ที่เลือกโดยขนส่งนี้แล้ว',
					type: 'error',
				})
				return false
			}

			const buffer = await file.arrayBuffer()
			const findCourierById: any = courierList.value.find(
				(each: any) => each._id === selectedCourier.value
			)

			// Flash
			if (findCourierById?.name === COURIER.FLASH) {
				const workbook = xlsx.read(buffer)
				const sheetName = workbook.SheetNames?.[0] // Hardcode flash always position 1
				const worksheet = workbook.Sheets[sheetName]
				const readExcel = xlsx.utils.sheet_to_json(worksheet)

				const reconciles = readExcel
					.map((each: any) => ({ trackingNumber: each['Tracking No.'] }))
					.filter((each: any) => each.trackingNumber)

				if (!reconciles?.length) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไฟล์ไม่ถูกต้องกรุณาตรวจสอบ',
						type: 'error',
					})
					return false
				}
			}

			// Thailand Post
			if (findCourierById?.name === COURIER.EMS) {
				//
				const workbook = xlsx.read(buffer)
				const sheetName = workbook.SheetNames?.[0]
				const worksheet = workbook.Sheets[sheetName]
				const readExcel = xlsx.utils.sheet_to_json(worksheet, {
					header: 'A',
					range: 3,
					blankrows: false,
				})

				const reconciles = readExcel
					.map((each: any) => ({ trackingNumber: each['D'] }))
					.filter((each: any) => each.trackingNumber)

				if (!reconciles?.length) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไฟล์ไม่ถูกต้องกรุณาตรวจสอบ',
						type: 'error',
					})
					return false
				}
			}

			// J&T
			if (findCourierById?.name === COURIER.JT) {
				const workbook = xlsx.read(buffer)
				const sheetName = workbook.SheetNames?.[1]
				const worksheet = workbook.Sheets[sheetName]
				const readExcel = xlsx.utils.sheet_to_json(worksheet, {
					header: 'A',
					range: 2,
				})
				const reconciles = readExcel
					.map((each: any) => ({ trackingNumber: each['A'] }))
					.filter((each: any) => each.trackingNumber)

				if (!reconciles?.length) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไฟล์ไม่ถูกต้องกรุณาตรวจสอบ',
						type: 'error',
					})
					return false
				}
			}
			//Kerry
			if (findCourierById?.name === COURIER.KERRY) {
				const workbook = xlsx.read(buffer)
				const sheetName = workbook.SheetNames?.[0]
				const worksheet = workbook.Sheets[sheetName]
				const readExcel = xlsx.utils.sheet_to_json(worksheet, {
					header: 'A',
					range: 5,
				})
				const reconciles = readExcel
					.map((each: any) => ({ trackingNumber: each['B'] }))
					.filter((each: any) => each.trackingNumber)

				if (!reconciles?.length) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไฟล์ไม่ถูกต้องกรุณาตรวจสอบ',
						type: 'error',
					})
					return false
				}
			}
			//SPX
			if (findCourierById?.name === COURIER.SPX) {
				const workbook = xlsx.read(buffer)
				const sheetName = workbook.SheetNames?.[0]
				const worksheet = workbook.Sheets[sheetName]
				const readExcel = xlsx.utils.sheet_to_json(worksheet, {
					header: 'A',
					range: 2,
				})
				const reconciles = readExcel
					.map((each: any) => ({ trackingNumber: each['F'] }))
					.filter((each: any) => each.trackingNumber)
				if (!reconciles?.length) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไฟล์ไม่ถูกต้องกรุณาตรวจสอบ',
						type: 'error',
					})
					return false
				}
			}
			//DHL
			if (findCourierById?.name === COURIER.DHL) {
				const workbook = xlsx.read(buffer)
				const sheetName = workbook.SheetNames?.[0]
				const worksheet = workbook.Sheets[sheetName]
				const readExcel = xlsx.utils.sheet_to_json(worksheet, {
					header: 'A',
					range: 6,
				})
				const reconciles = readExcel
					.map((each: any) => ({ trackingNumber: each['E'] }))
					.filter((each: any) => each.trackingNumber)
					
				if (!reconciles?.length) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไฟล์ไม่ถูกต้องกรุณาตรวจสอบ',
						type: 'error',
					})
					return false
				}
			}
			return true
		}

		onMounted(() => {
			getCourierList().then((response) => {
				courierList.value = response
				// default courier
				selectedCourier.value = response?.[0]?._id
			})
		})

		return {
			isSubmittingFile,
			confirmCancelReconcileFile,
			fileList,
			validateFile,
			selectedCourier,
			courierList,
			onRemove,
			onUploadFile,
			courierListFilter,
			state
		}
	},
})
