import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = (value) => _ctx.$emit('update:modelValue', value)),
    title: "เครียไฟล์กระทบยอด",
    width: "50%"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("ยกเลิก")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          loading: _ctx.isSubmittingFile,
          type: "success",
          onClick: _ctx.confirmCancelReconcileFile
        }, {
          default: _withCtx(() => [
            _createTextVNode("ยืนยัน")
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedCourier,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCourier) = $event)),
                placeholder: "เลือกขนส่ง",
                size: "large"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courierListFilter, (courier) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: courier._id,
                      label: courier.name,
                      value: courier._id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}